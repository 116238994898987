.aboutContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.2rem;
  justify-items: center;
  align-items: center;
  margin-bottom: 6.8rem;
}

.aboutTextBox {
}
.aboutImageBox {
  text-align: center;
}
.aboutImg {
  max-width: 90%;
  border-radius: 0.5rem;
}

/* --------Media queries-----------*/
@media (max-width: 75em) {
}

@media (max-width: 56.25em) {
  .aboutContainer {
    grid-template-columns: 1fr;
  }
  .aboutImageBox {
    grid-row-start: 1;
  }
}

@media (max-width: 37.5em) {
}
