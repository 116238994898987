.sectionVehicle {
  padding: 0;
}

.vehicle {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  column-gap: 5.2rem;
  row-gap: 3.2rem;
  justify-items: start;
  align-items: center;
  padding: 7rem 0;
}

.bikeSection {
  background-color: var(--background-color-bike);
}

.carSection {
  background-color: var(--background-color-car);
}

.vehicleCar {
  grid-template-columns: 1fr 1.5fr;
}

.vehicleBike {
  grid-template-columns: 1.5fr 1fr;
}

.vehicleImgBox {
  text-align: center;
}

.vehicleImgBoxCar {
  justify-self: end;
}

.vehicleImgBoxBike {
  justify-self: start;
}

.vehicleImg {
  width: 100%;
}

/* --------Media queries-----------*/

@media (max-width: 37.5em) {
}

@media (max-width: 56.25em) {
  .vehicleCar {
    grid-template-columns: 1fr;
  }

  .vehicleBike {
    grid-template-columns: 1fr;
  }
  .vehicleImgBoxCar {
    grid-row-start: 1;
  }
}

@media (max-width: 75em) {
}
