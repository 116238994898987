.footer {
  background-color: var(--color-secondary);
}

.footerGrid {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  gap: 6.2rem;
}
.footerLogo {
  height: 2rem;
}

.Logocol {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.addressCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
}
.linksCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;
}

.socialLinks {
  list-style: none;
  display: flex;
  gap: 5.2rem;
}
.socialLink {
  text-decoration: none;
}
.socialIcons {
  font-size: 2.4rem;
  color: var(--color-grey);
}

.copyright {
  font-size: 1.2rem;
  color: var(--color-grey);
  margin-top: auto;
}

.addressHead {
  color: var(--color-grey);
  font-size: 1.6rem;
  font-weight: 500;
}

.address {
  color: var(--color-grey);
  font-size: 1.4rem;
}

.pageLink {
  text-decoration: none;
  font-size: 1.6rem;
  color: var(--color-grey);
  font-weight: 500;
}

/* --------Media queries-----------*/

@media (max-width: 37.5em) {
}

@media (max-width: 56.25em) {
  .footerGrid {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
  .Logocol {
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
  }
  .addressCol {
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
  }
  .linksCol {
    flex-direction: row;
    justify-content: center;
    gap: 2.4rem;
  }
}

@media (max-width: 75em) {
}
