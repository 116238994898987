.galleryContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;
  justify-items: center;
  align-items: center;
  margin-bottom: 7.4rem;
}
.imageContainer {
  padding: 2.4rem;
  border: 0.5rem solid var(--color-grey);
  border-radius: 0.5rem;
  transition: var(--transition);
}

.imageContainer:hover {
  transform: scale(1.2);
  box-shadow: 0 1rem 2rem var(--color-shadow);
  border: 0.5rem solid var(--color-primary);
}
.galleryImage {
  width: 100%;
}

/* --------Media queries-----------*/

@media (max-width: 75em) {
  .galleryContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 56.25em) {
}

@media (max-width: 37.5em) {
  .galleryContainer {
    grid-template-columns: 1fr;
  }
}
