.mainHeader {
  background-color: var(--color-grey);
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoBox {
  margin: 1rem 0;
}

.logo {
  margin-top: 1rem;
  height: 2rem;
}

.deskNavLinks {
  display: flex;
  margin: 1rem 0;
  font-size: 1.6rem;
  align-items: center;
  gap: 5rem;
}

.deskNavLinks a,
.deskNavLinks a:link,
.deskNavLinks a:visited {
  display: inline-block;
  text-decoration: none;
  color: var(--color-secondary);
  padding: 0.8rem 0 0.5rem 0;
  border-bottom: 0.3rem solid transparent;
  transition: var(--transition);
}

.deskNavLinks a:hover,
.deskNavLinks a:active,
.deskNavLinks a.active {
  border-bottom: 0.3rem solid var(--color-primary);
}

.deskNavLinks a:last-child,
.deskNavLinks a:last-child:link,
.deskNavLinks a:last-child:visited {
  display: inline-block;
  text-decoration: none;
  color: var(--color-grey);
  background-color: var(--color-secondary);
  padding: 0.8rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: var(--transition);
}

.deskNavLinks a:last-child:hover {
  transform: translateY(-0.2rem);
  box-shadow: 0 1rem 2rem var(--color-shadow);
}

.deskNavLinks a:last-child:active {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem var(--color-shadow);
}

/* Mobile Navigation */

.mobNav {
  display: none;
}

.mobNavCheckbox {
  display: none;
}

.mobNavButton {
  background-color: var(--color-grey);
  height: 3.4rem;
  width: 3.4rem;
  position: fixed;
  top: 1rem;
  right: 5.2rem;
  border-radius: 50%;
  z-index: 30;
  box-shadow: 0 1rem 3rem var(--color-shadow);
  text-align: center;
}

.mobNavBackground {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  position: fixed;
  top: 1.5rem;
  right: 5.7rem;
  background-image: radial-gradient(
    var(--color-primary-light),
    var(--color-primary)
  );
  z-index: 10;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

.mobNavNavigation {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  opacity: 0;
  width: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mobNavList {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
}

.mobNavItem {
  margin: 2.4rem;
}

.mobNavLink {
  text-decoration: none;
  font-size: 3.2rem;
  padding: 0 4.8rem 1.6rem 0;
  color: var(--color-secondary);
  border-bottom: 0.2rem solid var(--color-secondary-light);
}

.mobNavCheckbox:checked ~ .mobNavBackground {
  transform: scale(200);
}

.mobNavCheckbox:checked ~ .mobNavNavigation {
  opacity: 1;
  width: 100%;
}

.mobNavIcon {
  position: relative;
  margin-top: 50%;
}

.mobNavIcon,
.mobNavIcon::before,
.mobNavIcon::after {
  width: 1.8rem;
  height: 0.2rem;
  background-color: var(--color-secondary);
  display: inline-block;
}

.mobNavIcon::before,
.mobNavIcon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}

.mobNavIcon::before {
  top: -0.6rem;
}

.mobNavIcon::after {
  top: 0.6rem;
}

.mobNavCheckbox:checked + .mobNavButton .mobNavIcon {
  background-color: transparent;
}

.mobNavCheckbox:checked + .mobNavButton .mobNavIcon::before {
  top: 0;
  transform: rotate(135deg);
}

.mobNavCheckbox:checked + .mobNavButton .mobNavIcon::after {
  top: 0;
  transform: rotate(-135deg);
}

/*
media query manager
0 - 600px       phone
600 - 900px     tablet portrait
900 - 1200px    tablet landscape
--------Media queries-----------*/

@media (max-width: 37.5em) {
}

@media (max-width: 56.25em) {
  .mobNav {
    display: block;
  }

  .deskNavLinks {
    display: none;
  }
}

@media (max-width: 75em) {
}
