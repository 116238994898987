.plans {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 3.2rem;
  justify-items: center;
  align-items: center;
}

.plan {
  max-width: 100%;
  border: 0.2rem solid var(--color-primary-lightest);
  border-radius: 1rem;
  padding: 3.2rem;
  box-shadow: 0 0.5rem 1rem var(--color-shadow);
}

.planComplete {
  border: 0;
  background-color: var(--color-primary-lightest);
  position: relative;
  overflow: hidden;
}

.planComplete::after {
  content: "Popular";
  position: absolute;
  top: 4%;
  right: -21%;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  background-color: var(--color-bestValue);
  padding: 0.8rem 6.4rem;
  transform: rotate(45deg);
}

.planHeader {
  text-align: center;
  margin-bottom: 2.4rem;
}

.planName {
  color: var(--color-primary);
  font-size: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
}

.planPrice {
  font-size: 3.2rem;
  font-weight: 500;
  color: var(--color-secondary);
  margin-bottom: 0.4rem;
}

.planPrice span {
  font-size: 1.8rem;
  font-weight: 400;
  margin-right: 0.8rem;
}

.planText {
  color: var(--color-secondary-lightest);
}

.adListItem {
  align-items: center;
}

.adListIcon {
  font-size: 2.4rem;
  color: var(--color-primary);
}

.planSignup {
  text-align: center;
}

/* --------Media queries-----------*/
@media (max-width: 75em) {
  .plans {
    grid-template-columns: repeat(2, 1fr);
  }

  .planComplete {
    grid-row-start: 2;
  }
}

@media (max-width: 56.25em) {
  .plans {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 37.5em) {
}
