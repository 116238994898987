@import url("https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,700;1,8..144,500&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: inherit;
}

:root {
  --color-primary: #cfae03;
  --color-secondary: #3a3d3f;

  --color-primary-light: #ddc64e;
  --color-primary-lightest: #f5eecc;
  --color-secondary-light: #616365;
  --color-secondary-lightest: #888a8b;

  --color-grey: #f5f5f5;
  --color-grey-dark: #c4c4c4;

  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-shadow: rgba(0, 0, 0, 0.2);

  --color-placeholder: #a00000;

  --color-gmail: #d93025;
  --color-whatsapp: #00a783;
  --color-facebook: #1877f2;

  --color-bestValue: #ffd43b;

  --background-color-car: #fce9e9;
  --background-color-bike: #f1eff4;

  --color-red: #e32323;
  --color-violet: #776191;

  --width-desk: 86%;
  --width-phone: 90%;

  --transition: all 0.2s ease-out;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto Serif", serif;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;
}

section {
  padding: 8.6rem 0;
}

footer {
  padding: 7.4rem 0;
}

p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-secondary-light);
}

.container {
  max-width: var(--width-desk);
  padding: 0 1.6rem;
  margin: 0 auto;
}

.headingPrimary,
.headingSecondary,
.headingTertiary {
  font-weight: 700;
  color: var(--color-secondary);
}
.headingPrimary {
  font-size: 4.9rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.headingSecondary {
  font-size: 3.9rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.headingTertiary {
  font-size: 3.1rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.subHeading {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-primary);
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

.btn,
.btn:link,
.btn:visited {
  margin-top: 2.4rem;
  text-decoration: none;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  transition: var(--transition);
}

.btn:hover {
  transform: translateY(-0.2rem);
  box-shadow: 0 1rem 2rem var(--color-shadow);
}

.btn:active {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem var(--color-shadow);
}

.btnGrey,
.btnGrey:link,
.btnGrey:visited {
  color: var(--color-grey);
  background-color: var(--color-secondary);
}

.btnRed,
.btnRed:link,
.btnRed:visited {
  background-color: var(--color-red);
  color: var(--color-grey);
}

.btnViolet,
.btnViolet:link,
.btnViolet:visited {
  background-color: var(--color-violet);
  color: var(--color-grey);
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-size: 1.6rem;
}

.listItem {
  display: flex;
  gap: 1.6rem;
}

.center {
  text-align: center;
}

@media (max-width: 75em) {
  html {
    font-size: 62.5%;
  }
}

@media (max-width: 56.25em) {
  html {
    font-size: 56.2%;
  }

  .headingSecondary {
    font-size: 3.9rem;
    line-height: 1.2;
    margin-bottom: 5.2rem;
  }
  section {
    padding: 7.4rem 0;
  }
}

@media (max-width: 37.5em) {
  section {
    padding: 6.2rem 0;
  }
}
