.sectionBio {
  margin-top: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 51.2rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(245, 245, 245, 0.8),
      rgba(196, 196, 196, 0.8)
    ),
    url(../../assets/vintage_saradhi.png);
  background-size: cover;
  background-position: 50%;
}

.BioText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.BioHeading {
  margin-bottom: 3.2rem;
}

.BioTextPara {
  font-weight: 500;
  font-style: italic;
  line-height: 3.2rem;
  text-align: center;
}

.BioTextPara span {
  font-weight: 700;
}

/* --------Media queries-----------*/

@media (max-width: 75em) {
}

@media (max-width: 56.25em) {
}

@media (max-width: 37.5em) {
}
