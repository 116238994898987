.contactContainer {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  background-color: var(--color-grey);
  border-radius: 0.5rem;
  padding: 12rem;
  margin-bottom: 7.4rem;
}

.contactOptions {
  display: flex;
  gap: 3.2rem;
  justify-content: center;
}

.contactOption {
  flex-grow: 1;
  flex-basis: 0;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 3.2rem;
  transition: var(--transition);
}

.contactOption:hover {
  transform: translateY(-0.2rem);
  box-shadow: 0 1rem 2rem var(--color-shadow);
}

.contactOptionLink {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
}

.contactOptionMailLink {
  color: var(--color-gmail);
}

.contactOptionMessLink {
  color: var(--color-facebook);
}

.contactOptionWhatLink {
  color: var(--color-whatsapp);
}

.contactOptionIcon {
  font-size: 3.2rem;
}

.contactOptionHead {
  font-size: 1.6rem;
  font-weight: 500;
}

.contactOptionText {
  font-size: 1.6rem;
  color: var(--color-secondary);
}

.form {
  display: flex;
  gap: 3.2rem;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 7.4rem;
}

.formInput {
  font-size: 1.6rem;
  padding: 1.6rem;
  border: 0.2rem solid var(--color-grey);
  border-radius: 0.5rem;
  resize: none;
  color: var(--color-secondary);
  transition: var(--transition);
}
::placeholder {
  color: var(--color-secondary-lightest);
  font-size: 1.6rem;
}

.formInput:focus {
  border: 0.2rem solid var(--color-grey-dark);
}

.formButton {
  border: 0;
  padding: 1.6rem 3.2rem;
  width: max-content;
}

/* --------Media queries-----------*/

@media (max-width: 75em) {
  .contactOptions {
    flex-direction: column;
  }
}

@media (max-width: 56.25em) {
  .contactContainer {
    padding: 4.8rem;
  }
}

@media (max-width: 37.5em) {
  .form {
    padding: 2.4rem;
  }
}
