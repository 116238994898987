.headerHero {
  background-color: var(--color-grey);
  padding-bottom: 3.2rem;
}

.hero {
  min-height: 80vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  gap: 5.2rem;
}

.heroLeft {
  margin-top: 5.2rem;
}

.heroRight {
  text-align: center;
}

.headline {
  display: block;
}

.heroHighlight {
  color: var(--color-primary);
}

.heroimg {
  width: 77%;
}

/* --------Media queries-----------*/

@media (max-width: 75em) {
}

@media (max-width: 56.25em) {
  .headerHero {
    padding: 4.8rem 0;
  }

  .hero {
    min-height: 100vh;
    grid-template-columns: 1fr;
    gap: 2.4rem;
  }

  .heroRight {
    grid-row-start: 1;
  }

  .heroLeft {
    margin-top: 0;
  }

  .heroimg {
    width: 81%;
  }
}

@media (max-width: 37.5em) {
}
